<template>
  <div class="container my-4">
    <div class="col-8">
      <h5>
        Skapa ny Säljorder
      </h5>
      <form
        novalidate
        @submit.prevent="submitForm"
      >
        <div class="mt-4 p-3 bg-white rounded-3 border shadow-sm">
          <dl class="mt-4 mb-1">
            <div class="row">
              <div class="col">
                <dt>
                  <label
                    for="customer"
                    class="required"
                  >Kund & Leveransadress</label>
                </dt>
                <dd>
                  <CustomerSearch
                    ref="customerSearch"
                    v-model="recipient"
                  />
                </dd>
              </div>
            </div>
            <div class="row row-cols-2">
              <div class="col">
                <dt>
                  <label
                    for="shipping_method"
                    class="required"
                  >Leveransmetod</label>
                </dt>
                <dd>
                  <ShippingMethodSelect
                    v-model="sales_order.shipping_method_id"
                    :customer="sales_order.customer"
                  />
                </dd>
              </div>
              <div class="col">
                <dt>
                  <label for="buyer_name">Beställare</label>
                </dt>
                <dd>
                  <input
                    id="buyer_name"
                    v-model="sales_order.buyer_name"
                    type="text"
                    class="form-control"
                    data-test="buyer-name-input"
                  >
                </dd>
              </div>
            </div>
            <div class="row row-cols-2">
              <div class="col">
                <dt>
                  <label
                    for="customer_order_number"
                    class="required"
                  >Kunds order-nummer</label>
                </dt>
                <dd>
                  <input
                    v-model="sales_order.customer_order_number"
                    class="form-control"
                    data-test="customer-order-number-input"
                    :class="{'is-invalid': v$.sales_order.customer_order_number.$error}"
                  >
                  <InputErrorDisplay :errors="v$.sales_order.customer_order_number.$errors" />
                </dd>
              </div>
              <div class="col">
                <dt>
                  <label
                    for="lms"
                  >LMS</label>
                </dt>
                <dd>
                  <input
                    id="lms"
                    v-model="sales_order.lms"
                    type="checkbox"
                    class="form-check-input"
                    data-test="lms-checkbox"
                  >
                </dd>
              </div>
            </div>
            <div class="row row-cols-2">
              <div class="col">
                <dt>
                  <label
                    for="confirmed_by_name"
                    class="required"
                  >Behörig beställare</label>
                </dt>
                <dd>
                  <input
                    id="confirmed_by_name"
                    v-model="sales_order.confirmed_by_name"
                    type="text"
                    class="form-control"
                    data-test="confirmed-by-name-input"
                  >
                </dd>
              </div>
              <div class="col">
                <dt>
                  <label
                    for="confirmed_by_role"
                    class="required"
                  >Behörig beställares roll</label>
                </dt>
                <dd>
                  <input
                    id="confirmed_by_role"
                    v-model="sales_order.confirmed_by_role"
                    type="text"
                    class="form-control"
                    data-test="confirmed-by-name-input"
                  >
                </dd>
              </div>
            </div>
          </dl>
        </div>

        <div class="mt-4 ms-2">
          <h5>
            Varor
          </h5>
        </div>

        <OrderFormLinesInput v-model="sales_order.lines_attributes" />

        <div
          v-if="errors.length"
          class="alert alert-danger mt-3"
          role="alert"
        >
          <div class="d-flex fs-4">
            <i class="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" />
            <p class="alert-heading">
              Kunde inte skapa order
            </p>
          </div>
          <ul>
            <li
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="mt-3 py-3">
          <LoadingButton
            class="btn btn-primary shadow-sm float-end submit"
            data-test="submit-order-button"
            :loading="submitting"
          >
            <template #perform>
              Skapa Säljorder
            </template>
            <template #loading>
              Skapar...
            </template>
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import router from '@/config/router'

import ShippingMethodSelect from '@/components/ShippingMethodSelect.vue'
import CustomerSearch from '@/components/CustomerSearch.vue'
import OrderFormLinesInput from '@/components/OrderFormLinesInput.vue'
import InputErrorDisplay from '@/components/InputErrorDisplay.vue'
import LoadingButton from '@/components/LoadingButton.vue'
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@/utils/i18n-validators'
import performanceTimer from '@/composables/performanceTimer'

export default {
  components: {
    CustomerSearch,
    OrderFormLinesInput,
    ShippingMethodSelect,
    InputErrorDisplay,
    LoadingButton,
  },
  inject: ['axios'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      recipient:   null,
      submitting:  false,
      sales_order: {
        address:               null,
        buyer_name:            null,
        customer:              null,
        customer_order_number: null,
        lines_attributes:      [{ item: null, quantity: null }],
        shipping_method_id:    null,
        lms:                   false,
        confirmed_by_name:     null,
        confirmed_by_role:     null,
      },
      errors: [],
    }
  },
  created () {
    this.$watch('recipient', (newRecipient) => {
      this.sales_order.customer = newRecipient?.customer
      this.sales_order.address = newRecipient?.address
    })
  },
  mounted () {
    this.$refs.customerSearch.focusInput()
    performanceTimer.start('manual_order_time_to_approved')
  },
  validations () {
    return {
      sales_order: {
        customer_order_number: {
          required,
          maxLength: maxLength(45),
        },
      },
    }
  },
  methods: {
    async submitForm () {
      this.v$.$reset()
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) return
      this.submitting = true
      this.axios.post('/internal_api/sales_orders', this.buildSubmitBody())
        .then((response) => {
          router.push({ name: 'showSalesOrder', params: { id: response.data.data.id } })
        }).catch((error) => {
          this.errors = error.response.data.message.errors
        }).then(() => {
          this.submitting = false
        })
    },
    buildSubmitBody () {
      return {
        sales_order: {
          address_id:            this.sales_order.address?.id,
          buyer_name:            this.sales_order.buyer_name,
          customer_id:           this.sales_order.customer?.id,
          customer_order_number: this.sales_order.customer_order_number,
          lines_attributes:      this.sales_order.lines_attributes.map(line => ({ item_id: line.item?.id, quantity: line.quantity, location_id: this.$store.state.settings.current_user.location.id })),
          location_id:           this.$store.state.settings.current_user.location.id,
          shipping_method_id:    this.sales_order.shipping_method_id,
          lms:                   this.sales_order.lms,
          confirmed_by_name:     this.sales_order.confirmed_by_name,
          confirmed_by_role:     this.sales_order.confirmed_by_role,
        },
      }
    },
  },
}

</script>

<style scoped>
  .submit {
    width: 10em;
  }
</style>
