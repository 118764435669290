import { createRouter, createWebHistory } from 'vue-router'
import SalesOrdersApprovedIndex from '@/views/SalesOrdersApprovedIndex.vue'
import SalesOrdersIndex from '@/views/SalesOrdersIndex.vue'
import SalesOrdersNew from '@/views/SalesOrdersNew.vue'
import SalesOrdersShow from '@/views/SalesOrdersShow.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path:      '/',
    name:      'Home',
    component: SalesOrdersIndex,
  },
  {
    path:      '/sales_orders/approved',
    name:      'indexApprovedSalesOrders',
    component: SalesOrdersApprovedIndex,
  },
  {
    path:      '/sales_orders/new',
    component: SalesOrdersNew,
  },
  {
    path:      '/sales_orders/:id',
    component: SalesOrdersShow,
    name:      'showSalesOrder',
  },
  {
    path:      '/:pathMatch(.*)*',
    name:      'NotFound',
    component: NotFound,
  },
]

export default createRouter({
  history: createWebHistory(),
  routes:  routes,
  scrollBehavior () {
    return { top: 0 }
  },
})
